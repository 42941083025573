













































































































import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const auth = namespace('auth')

@Component
export default class Deposit extends Vue {
	@auth.State
	private username!: string
}
